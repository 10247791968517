'use strict';
const Client = require('shopify-buy');
const { navigation } = require('./navigation.js');

navigation();

document.addEventListener('DOMContentLoaded', function () {
	console.log('tests');
});

const isShopifyClientEnabled = false;
if (isShopifyClientEnabled) {
	// Initializing a client to return content in the store's primary language
	const client = Client.buildClient({
		domain: 'powerpuff-playground.myshopify.com',
		storefrontAccessToken: 'd58922d0636a0279c41cc0ac950b4c6a',
	});

	// Fetch all products in your shop
	client.product.fetchAll().then((products) => {
		// Do something with the products
		display(products, 3);
		console.log(products);
	});

}

function display(products) {
	function getProductMarkup(data) {
		const productMarkup = `<div class="column">
		<div class="card">
			<div class="card-image">
				<figure class="image is-4by3">
					<img src="${data.images[0].src}" alt="photo of a ${data.title}">
				</figure>
			</div>
			<div class="card-content">
				<div class="media">
					<div class="media-content">
						<p class="title is-4">${data.title}</p>
					</div>
				</div>

				<div class="content">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Phasellus nec iaculis mauris.
				</div>
				<footer class="card-footer">
					<a href="#" class="card-footer-item">Buy now</a>
					<a href="#" class="card-footer-item">View product</a>
				</footer>
			</div>
			</div>
		</div>`;
		return productMarkup;
	}
	const productSection = document.getElementById('product-listing');
	if (productSection !== null) {
		products.forEach((product) => {
			productSection.querySelector('.columns').innerHTML =
				productSection.querySelector('.columns').innerHTML +
				getProductMarkup(product);
		});
	}
}